import { SalesAidLimitViolationView } from '../../../../../../generated/ApiClient';
import i18n from '../../../../../../common/i18n/i18n';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { HeadCell } from '../../../../../../common/table/EnhancedTableHead';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '../../../../../../common/table/EnhancedTable';

interface Props {
  violations: SalesAidLimitViolationView[];
  recipientUnits: { [index: number]: string };
}

interface LimitRow {
  id: string;
  subsidyRecipient: string;
  limit: string;
  subsidySum: string;
  initialValue: string;
}

export function toSubsidyDisplayText(
  value: number,
  recipientId: number,
  recipientUnits: { [index: number]: string }
) {
  return `${value.toLocaleString(
    'de-DE',
    recipientUnits[recipientId] !== 'Monat(e)'
      ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : undefined
  )} ${recipientUnits[recipientId]}`;
}

const ExtendedConfigurationViolations = ({ violations, recipientUnits }: Props) => {
  const { t } = useTranslation();

  const columns: readonly HeadCell<LimitRow>[] = [
    {
      id: 'subsidyRecipient',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidyRecipient'),
    },
    {
      id: 'initialValue',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.initialValue'),
    },
    {
      id: 'subsidySum',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.subsidySum'),
    },
    {
      id: 'limit',
      disablePadding: false,
      align: 'left',
      label: t('deals.new.financesConfiguration.calculationDrawer.salesAids.limit'),
    },
  ];

  const rows = violations.map((s, index) => ({
    id: `${index}`,
    subsidyRecipient: i18n.t([
      `deals.new.financesConfiguration.calculationDrawer.subsidyValues.${s.subsidyRecipient}`,
      '',
    ]),
    initialValue: toSubsidyDisplayText(s.initialValue, s.subsidyRecipient, recipientUnits),
    limit: toSubsidyDisplayText(s.limit, s.subsidyRecipient, recipientUnits),
    subsidySum: toSubsidyDisplayText(s.subsidySum, s.subsidyRecipient, recipientUnits),
  }));

  if (!violations.length)
    return null;

  return (
    <Stack flexDirection="column">
      <Typography variant="h4">
          {t('deals.new.financesConfiguration.calculationDrawer.extendedConfiguration.violation')}
        </Typography>
        <EnhancedTable
          rows={rows}
          variant="default"
          headCells={columns}
          hideCheckbox={true}
          minWidth={300}
        />
    </Stack>
  );
};

export default ExtendedConfigurationViolations;
