import { useNavigate, useParams } from 'react-router';
import RestHttpClient from '../../../common/RestHttpClient';
import {
  IGetDealResponse,
  LocalApiMessage,
  NotificationLocation,
  ReplaceMainCustomerRequest,
} from '../../../generated/ApiClient';
import { useEffect, useRef, useState } from 'react';
import { DealHeader } from './common/DealHeader';
import {
  Button,
  Divider,
  Grid,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Typography,
  Stack,
  Link,
  Dialog,
} from '@mui/material';
import { ExistingDealInfo } from './common/ExistingDealInfo';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../../component/TabPanel';
import { DealDetailFinances } from './finances/DealDetailFinances';
import { Icons } from '../../../component/icons/Icons';
import { DealDetailInsurances } from './insurances/DealDetailInsurances';
import { EditDealCustomer } from './customer/EditDealCustomer';
import { EditDealApplicant } from './applicant/EditDealApplicant';
import { ModelImage } from '../ModelImage';
import { usePermissions } from '../../../common/auth/PermissionsContext';
import { pushNotificationHandler } from '../../../common/PushNotification';
import { CustomerMergeDialog } from '../../customers/merge/CustomerMergeDialog';
import { EditDealDocuments } from './documents/EditDealDocuments';
import SideDrawer from '../../../component/SideDrawer';
import { confirmHandler } from '../../../common/ConfirmModal';
import CloseIcon from '@mui/icons-material/Close';
import { Info } from '@mui/icons-material';
import { CustomerOrderAmendDialog } from './customer/ConfirmOrderAmendDialog';
import { useNotifications } from '../../../common/global/useNotifications';
import { SalesPersonSelection } from '../new/model/SalesPersonSelection';
import { ChangeCustomerDialog } from './customer/ChangeCustomerDialog';

const dealTypePrivate = 1;
const creationStateStaging = 1;
const customerTypePrivate = 1;
const customerTypeCommercial = 2;

export type CoApplicantValidationErrors = {
  [key: number]: LocalApiMessage[];
};

export function DealDetail() {
  const { id, calculationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canViewInsurance = hasPermission('InsuranceProducts', 'View');
  const canViewCustomer = hasPermission('Customers', 'View');
  const canViewFinances =
    hasPermission('FinancingProducts', 'View') || hasPermission('LeasingProducts', 'View');
  const canChangeCustomer = hasPermission('CustomerSelection', 'Execute');
  const canChangeDealer = hasPermission('DealerSelection', 'Execute');

  const shouldUnlockDeal = hasPermission('Deals', 'Edit');

  const [tabValue, setTabValue] = useState(0);
  const [deal, setDeal] = useState<IGetDealResponse>();
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMergeOpen, setIsMergeOpen] = useState(false);
  const [isAmendmentOpen, setIsAmendmentOpen] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [isCustomerSelectionOpen, setCustomerSelectionOpen] = useState<boolean>(false);
  const [isSalesPersonSelectionOpen, setSalesPersonSelectionOpen] = useState<boolean>(false);
  const submitButtonRef = useRef(null);

  const [customerValidationErrors, setCustomerValidationErrors] = useState<LocalApiMessage[]>([]);
  const [coApplicantErrors, setCoApplicantErrors] = useState<CoApplicantValidationErrors>({});
  const readonly = Boolean(calculationId) || deal?.isReadonly || Boolean(deal?.amendedByDealId);

  useNotifications(NotificationLocation.Deal);

  const fetchDealInfo = async (id: number) => {
    try {
      setLoading(true);
      const { data } = await RestHttpClient.getDeal(id);
      setDeal(data);

      if (data.creationState === creationStateStaging) {
        if (data.amendsDealId) setIsAmendmentOpen(true);
        else setIsMergeOpen(true);
      }
      if (!data.modelIsActive) {
        pushNotificationHandler.publish(t('deals.modelIsInactive'), 'error');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerValid = (dealId: number, customerId: number) => {
    RestHttpClient.isValidCustomer(dealId, customerId).then((res) => {
      setIsValidCustomer(res.data);

      setCustomerValidationErrors(res.messages.local.filter((e) => e.target.includes('customer')));

      function extractPrefixNumber(str: string) {
        const regex = /^(\d+)/; // starts with one or more digits
        const match = str.match(regex);
        if (match) {
          return parseInt(match[1]); // parse the matched digits as an integer
        }
        return null; // no match found
      }

      setCoApplicantErrors(
        res.messages.local
          .filter((e) => !e.target.includes('customer') && !e.target.includes('deal'))
          .reduce((errors, e) => {
            const id = extractPrefixNumber(e.target);
            if (id) {
              const existingErrors = errors[id] ?? [];
              errors[id] = [...existingErrors, e];
            }
            return errors;
          }, {} as CoApplicantValidationErrors)
      );

      setErrorMsgs(
        // @ts-ignore
        res.messages.local.map((e) => {
          if (!e.translate) return e.message;

          // @ts-ignore
          const translatedMsg = t(`errors.${e.message}`);
          if (e.target.includes('customer') || e.target.includes('deal')) {
            // @ts-ignore
            return translatedMsg;
          }

          return `${t('deals.edit.sidebar.coApplicants')}: ${translatedMsg}`;
        })
      );
    });
  };

  const reloadCustomerValid = () => {
    if (id && deal && deal.customerId) {
      fetchCustomerValid(Number(id), deal.customerId);
    }
  };

  useEffect(() => {
    reloadCustomerValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  useEffect(() => {
    if (id) {
      fetchDealInfo(Number(id));
    }
  }, [id]);

  const handleMerge = (customerIds: string[]) => {
    if (customerIds.length === 1) {
      const newCustomerId = parseInt(customerIds[0]);

      const req = new ReplaceMainCustomerRequest();
      req.sourceCustomerId = newCustomerId;
      req.targetCustomerIds = [Number(deal?.customerId)];
      req.updateDealCustomer = true;

      RestHttpClient.replaceMainCustomer(req).then(() => {
        if (deal) {
          const newDeal = {
            ...deal,
            customerId: newCustomerId,
          };

          setDeal(newDeal);
        }

        pushNotificationHandler.publish(t('customers.saved'), 'success');
        setIsMergeOpen(false);
        setTabValue(customerTabIndex);
      });
    } else {
      console.log('invalid selection', customerIds);
    }
  };

  const handleAmend = () => {
    return RestHttpClient.confirmAmendedCustomer(Number(id)).then((res) => {
      if (deal) {
        const newDeal = {
          ...deal,
          customerId: res.data.newCustomerId,
        };

        setDeal(newDeal);
      }

      pushNotificationHandler.publish(t('customers.saved'), 'success');
      setIsAmendmentOpen(false);
      setTabValue(customerTabIndex);
    });
  };

  const showAmendInfo = () => {
    pushNotificationHandler.publish(t('deals.amendment.creditCheckInfo'), 'info');
  };

  const handleNewCustomer = () => {
    return RestHttpClient.confirmStagingCustomer(Number(id)).then((res) => {
      if (!res.data.isValid) {
        pushNotificationHandler.publish(t('customers.invalidCustomer'), 'info');
      }

      setIsMergeOpen(false);
      setIsAmendmentOpen(false);
      setTabValue(customerTabIndex);
    });
  };

  const handleTabChange = (index: number) => {
    const success = () => {
      if (submitButtonRef && submitButtonRef.current) {
        // @ts-ignore
        submitButtonRef.current.click();
      }
      setTabValue(index);
      setUnsavedChanges(false);
    };

    const noSave = () => {
      setTabValue(index);
      setUnsavedChanges(false);
    };

    if (!unsavedChanges) {
      noSave();
    } else {
      confirmHandler.confirm(
        t('deals.edit.sidebar.unsavedChanges'),
        success,
        noSave,
        t('deals.edit.sidebar.unsavedChangesText'),
        t('deals.edit.sidebar.continue'),
        t('deals.edit.sidebar.cancel')
      );
    }
  };

  // remove lock on deal
  const handleDealClose = async () => {
    if (shouldUnlockDeal) {
      await RestHttpClient.unlockDeal(Number(id));
    }
    navigate('/deals');
  };

  const handleSelectCustomer = async (customerId: string) => {
    setLoading(true);
    setCustomerSelectionOpen(false);

    try {
      await RestHttpClient.replaceDealCustomer(Number(id), Number(customerId));
    } finally {
      setLoading(false);
    }

    await fetchDealInfo(Number(id));
  };

  const handleSelectSalesPerson = async (event: React.SyntheticEvent, value: any) => {
    setLoading(true);
    setSalesPersonSelectionOpen(false);

    try {
      if (value && 'value' in value) {
        const numVal = Number(value.value);
        await RestHttpClient.setSalesPerson(Number(id), numVal);
      }
    } finally {
      setLoading(false);
    }

    await fetchDealInfo(Number(id));
    
  };

  const calculationTabIndex = 0;
  const insuranceTabIndex = 1;
  const customerTabIndex = 2;
  const coApplicantTabIndex = 3;
  const documentsTabIndex = 4;

  return (
    <Box
      sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      className="edit-deal"
    >
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <Button
          endIcon={<CloseIcon />}
          variant="outlined"
          color="secondary"
          onClick={handleDealClose}
        >
          {t('deals.edit.lock.close')}
        </Button>
        {canChangeCustomer && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setCustomerSelectionOpen(true)}
          >
            {t('deals.edit.changeCustomer')}
          </Button>
        )}
        {canChangeDealer && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setSalesPersonSelectionOpen(true)}
          >
            {t('deals.edit.changeSalesPerson')}
          </Button>
        )}
        {deal?.amendedByDealId && (
          <Stack flexDirection="row" gap="0.5em">
            {Icons.alert()}
            <Typography>{t('deals.amendment.amendedByDeal')}</Typography>
            <Link href={'/deals/' + deal.amendedByDealId}>{deal.amendedByDealId}</Link>
          </Stack>
        )}
        {deal?.amendsDealId && (
          <Stack flexDirection="row" gap="0.5em">
            <Info color="info" />
            <Typography>{t('deals.amendment.amendsDeal')}</Typography>
            <Link href={'/deals/' + deal.amendsDealId}>{deal.amendsDealId}</Link>
          </Stack>
        )}
      </Box>

      <Grid container sx={{ width: '100%' }}>
        {loading && <LinearProgress />}
        <Grid item xs={9}>
          <DealHeader deal={deal} />
          <ExistingDealInfo deal={deal} isLoading={loading} />
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: 400,
              margin: '0.5%',
              backgroundSize: 'contain',
            }}
          >
            <ModelImage modelId={deal?.modelId} />
          </Box>
        </Grid>
      </Grid>
      <Divider orientation="horizontal" />

      <SideDrawer
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        left={
          <List>
            <ListItemButton
              disabled={!canViewFinances}
              selected={tabValue === calculationTabIndex}
              onClick={() => handleTabChange(calculationTabIndex)}
            >
              <ListItemText primary={t('deals.edit.sidebar.calculation')} />
              <ListItemIcon>{Icons.calculations()}</ListItemIcon>
            </ListItemButton>
            <ListItemButton
              disabled={!canViewInsurance}
              selected={tabValue === insuranceTabIndex}
              onClick={() => handleTabChange(insuranceTabIndex)}
            >
              <ListItemText primary={t('deals.edit.sidebar.insurance')} />
              <ListItemIcon>{Icons.insurance()}</ListItemIcon>
            </ListItemButton>
            <ListItemButton
              disabled={!canViewCustomer}
              selected={tabValue === customerTabIndex}
              onClick={() => handleTabChange(customerTabIndex)}
            >
              <ListItemText primary={t('deals.edit.sidebar.customer')} />
              <ListItemIcon>
                {customerValidationErrors.length ? Icons.customerRed() : Icons.customer()}
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              disabled={!canViewCustomer}
              selected={tabValue === coApplicantTabIndex}
              onClick={() => handleTabChange(coApplicantTabIndex)}
            >
              <ListItemText primary={t('deals.edit.sidebar.coApplicants')} />
              <ListItemIcon>
                {Object.keys(coApplicantErrors).length > 0
                  ? Icons.coApplicantsRed()
                  : Icons.coApplicants()}
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              selected={tabValue === documentsTabIndex}
              onClick={() => handleTabChange(documentsTabIndex)}
            >
              <ListItemText primary={t('deals.edit.sidebar.documents')} />
              <ListItemIcon>{Icons.documents()}</ListItemIcon>
            </ListItemButton>
          </List>
        }
        right={
          <>
            {' '}
            {!loading && deal && id && (
              <>
                <TabPanel sx={{ p: 0, pl: 1 }} index={calculationTabIndex} value={tabValue}>
                  {canViewFinances ? (
                    <DealDetailFinances
                      dealId={Number(id)}
                      isValidCustomer={isValidCustomer}
                      isIncludingVat={deal.isIncludeVat}
                      validationErrors={errorMsgs}
                      calculationId={calculationId ? Number(calculationId) : undefined}
                      readonly={readonly}
                      singleInsuranceId={deal.singleInsuranceId}
                      reload={() => fetchDealInfo(Number(id))}
                      modelIsActive={deal.modelIsActive}
                      vehicleAgeIsValid={deal.vehicleAgeIsValid}
                      amendedByDealId={deal.amendedByDealId}
                      vinParameters={deal}
                    />
                  ) : (
                    <span />
                  )}
                </TabPanel>
                <TabPanel sx={{ p: 0, pl: 1 }} index={insuranceTabIndex} value={tabValue}>
                  {canViewInsurance ? (
                    <DealDetailInsurances
                      dealId={Number(id)}
                      customerId={Number(deal.customerId)}
                      readonly={readonly}
                      reload={() => fetchDealInfo(Number(id))}
                    />
                  ) : (
                    <span />
                  )}
                </TabPanel>
                <TabPanel sx={{ p: 0, pl: 1 }} index={customerTabIndex} value={tabValue}>
                  {canViewCustomer ? (
                    <EditDealCustomer
                      dealId={Number(id)}
                      customerId={Number(deal.customerId)}
                      errors={customerValidationErrors}
                      readonlyOverwrite={readonly === true}
                      reload={() => reloadCustomerValid()}
                      setUnsavedChanges={setUnsavedChanges}
                      submitRef={submitButtonRef}
                    />
                  ) : (
                    <span />
                  )}
                </TabPanel>
                <TabPanel sx={{ p: 0, pl: 1 }} index={coApplicantTabIndex} value={tabValue}>
                  {canViewCustomer ? (
                    <EditDealApplicant
                      dealId={Number(id)}
                      dealCustomerId={deal.customerId}
                      dealType={deal.dealTypeId}
                      readonlyOverwrite={readonly === true}
                      validationErrors={coApplicantErrors}
                      reload={() => reloadCustomerValid()}
                      setUnsavedChanges={setUnsavedChanges}
                      submitRef={submitButtonRef}
                    />
                  ) : (
                    <span />
                  )}
                </TabPanel>
                <TabPanel sx={{ p: 0, pl: 1 }} index={documentsTabIndex} value={tabValue}>
                  <EditDealDocuments dealId={Number(id)} reload={() => reloadCustomerValid()} />
                </TabPanel>
              </>
            )}
          </>
        }
      />

      {!readonly && (
        <>
          <CustomerMergeDialog
            customerId={Number(deal?.customerId)}
            handleMerge={handleMerge}
            handleCancel={handleNewCustomer}
            isOpen={isMergeOpen}
            setIsOpen={setIsMergeOpen}
            isPrivate={deal?.dealTypeId === dealTypePrivate}
            isPoseidon
          />
          <ChangeCustomerDialog
            customerTypeId={
              deal?.dealTypeId === dealTypePrivate ? customerTypePrivate : customerTypeCommercial
            }
            handleSelect={handleSelectCustomer}
            isOpen={isCustomerSelectionOpen}
            setIsOpen={setCustomerSelectionOpen}
            customerId={deal?.customerId}
          />
          <Dialog
            open={isSalesPersonSelectionOpen}
            maxWidth="lg"
            onClose={() => setSalesPersonSelectionOpen(false)}
          >
            <Box className='sales-person-dialog'>
              <SalesPersonSelection handleChanged={handleSelectSalesPerson} selected={deal?.salesPersonId} />
            </Box>
          </Dialog>
          <CustomerOrderAmendDialog
            handleConfirm={async () => {
              await handleAmend();
              showAmendInfo();
            }}
            handleCancel={async () => {
              await handleNewCustomer();
              showAmendInfo();
            }}
            isOpen={isAmendmentOpen}
            setIsOpen={setIsMergeOpen}
          />
        </>
      )}
    </Box>
  );
}
