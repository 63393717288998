import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { SplitPanel } from '../../../../component/SplitPanel';
import { ModelSelection } from './ModelSelection';
import { ModelConfiguration } from './ModelConfiguration';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import { useActor } from '@xstate/react';
import { StateMachineContext } from '../state/StateMachineContextProvider';
import { CONFIRM_MODEL } from '../state/orderStateMachine';
import {
  dateLessOrEqualToday,
  requiredPosDouble,
  integerGreaterZero,
  discount,
  chassisNumber,
} from '../../../../common/validation/Validations';
import RestHttpClient from '../../../../common/RestHttpClient';
import { pushNotificationHandler } from '../../../../common/PushNotification';
import { confirmHandler } from '../../../../common/ConfirmModal';
import { t } from 'i18next';
import { VinState } from '../../../../generated/ApiClient';
import { grossToNet, netToGross } from '../../../../common/util/VatUtil';

const validateVehicleAge = async (
  modelId: number | undefined,
  vehicleCondition: number | undefined,
  dealTypeId: number | undefined,
  registrationDate: Date | undefined
): Promise<boolean> => {
  if (
    modelId !== undefined &&
    vehicleCondition !== undefined &&
    dealTypeId !== undefined &&
    registrationDate !== undefined &&
    !isNaN(registrationDate?.valueOf())
  ) {
    return RestHttpClient.validateVehicleAge(
      modelId,
      vehicleCondition,
      dealTypeId,
      registrationDate
    ).then((res) => {
      return res.data.isValid;
    });
  }
  return Promise.resolve(true);
};

const schema = z
  .object({
    businessType: z.number().nonnegative(),
    distributionChannel: z.number(),
    discountCategory: z.number().optional(),
    pricesIncludingVAT: z.boolean(),
    modelAddition: z.string().optional(),
    vehicleCondition: z.number().nonnegative(),
    numberOfVehicles: z.number().nonnegative(),
    registrationDate: dateLessOrEqualToday.optional(),
    mileage: integerGreaterZero,
    commissionNumber: z.string().optional(),
    listPrice: z.number().nonnegative().min(1),
    specialEquipment: z.number().nonnegative(),
    lpSaTotal: z.number().nonnegative(),
    discountInPercentages: discount,
    discountInEuro: requiredPosDouble,
    totalPrice: z.number().nonnegative().min(1),
    modelId: z.number(),
    sellerId: z.number().optional(),
    vehicleAgeIsValid: z.boolean().optional(),
    modelPrototypeId: z.number().optional(),
    chassisNumber: chassisNumber.optional(),
    vinDate: z.date().optional().nullable(),
    vinIssue: z.string().optional().nullable(),
    vinStatus: z.string().optional().nullable(),
    vinState:  z.nativeEnum(VinState).optional().nullable()
  })
  .refine((args) => !(args.distributionChannel === 11 && args.discountCategory === undefined), {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['discountCategory'], // path of error
  })
  .refine((args) => !(args.vehicleCondition !== 1 && !args.registrationDate), {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['registrationDate'],
  })
  .refine((args) => !(args.vehicleCondition !== 1 && !args.mileage), {
    message: t('errors.invalid_type', { ns: 'zod' }),
    path: ['mileage'],
  });

export type ModelFormValues = z.infer<typeof schema>;

export function ModelForm() {
  const stateMachine = useContext(StateMachineContext);
  const [state, send] = useActor(stateMachine);
  const [open, setOpen] = useState(!state.context.model?.modelId);
  const [pricesIncludingVAT, setPricesIncludingVAT] = useState(
    state.context.model?.pricesIncludingVAT ?? true
  );
  const [isDistributionChannelEditable, setIsDistributionChannelEditable] = useState(true);
  const [showDemonstrationCarMessage, setShowDemonstrationCarMessage] = useState(false);

  const {
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<ModelFormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      pricesIncludingVAT: true,
      specialEquipment: 0,
      discountInPercentages: 0,
      discountInEuro: 0,
      numberOfVehicles: 1,
      vehicleCondition: 1,
      listPrice: 0,
      lpSaTotal: 0,
      totalPrice: 0,
      sellerId: undefined,
      ...state.context.model,
    },
  });

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    handleSubmit(
      async (values) => {
        console.log('submitting');

        const sendCallback = () =>
          send({
            type: CONFIRM_MODEL,
            info: values,
          });

        setTimeout(() => {
          console.log(
            'most up-to-date values: ' + JSON.stringify(stateMachine.getSnapshot().context)
          );
        }, 1000);

        const validVehicleAge = await validateVehicleAge(
          values.modelId,
          values.vehicleCondition,
          values.businessType,
          values.registrationDate
        );

        values.vehicleAgeIsValid = validVehicleAge;

        if (!validVehicleAge) {
          pushNotificationHandler.publish(
            t(
              'deals.new.modelConfiguration.body.vehicleDetails.thisCarIsTooOldToDoCalculationWithIt'
            ),
            'warning'
          );
        }

        if (!values.specialEquipment) {
          confirmHandler.confirm(t('deals.new.modelConfiguration.confirmNoSpecial'), sendCallback);
        } else {
          sendCallback();
        }
      },
      (err) => {
        if (err.modelId) {
          pushNotificationHandler.publish(t('deals.new.modelConfiguration.modelError'), 'warning');
        }
        console.error(err);
      }
    )(event);
  };

  const onModelSelectionChanged = (modelId: number | null) => {
    const sellerId = getValues('sellerId');
    reset({
      pricesIncludingVAT: true,
      specialEquipment: 0,
      discountInPercentages: 0,
      discountInEuro: 0,
      numberOfVehicles: 1,
      vehicleCondition: 1,
      listPrice: 0,
      lpSaTotal: 0,
      totalPrice: 0,
      sellerId,
    });
    if (modelId) {
      setValue('modelId', modelId);
      setOpen(false);
    }
  };

  const onSellerIdChanged = (sellerId: number | null) => {
    if (sellerId) {
      setValue('sellerId', sellerId);
    }
  };

  const fetchPredefinedValues = (modelId: number) => {
    RestHttpClient.getPredefinedValues(modelId).then((res) => {
      setValue('pricesIncludingVAT', res.data.isIncludeVat);
      setPricesIncludingVAT(res.data.isIncludeVat);
      setValue('distributionChannel', res.data.distributionChannel);
      setIsDistributionChannelEditable(res.data.isDistributionChannelEditable);
      setShowDemonstrationCarMessage(res.data.showDemonstrationCarMessage);
    });
  };

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (
        name === 'pricesIncludingVAT' &&
        type === 'change' &&
        value.pricesIncludingVAT !== undefined
      ) {
        setPricesIncludingVAT(value.pricesIncludingVAT);
        const { listPrice, specialEquipment, lpSaTotal, discountInEuro } = value;
        if (value.pricesIncludingVAT) {
          if (listPrice) setValue('listPrice', netToGross(listPrice));
          if (specialEquipment) setValue('specialEquipment', netToGross(specialEquipment));
          if (lpSaTotal) {
            const total = netToGross(listPrice) + netToGross(specialEquipment);
            const discount = netToGross(discountInEuro);
            setValue('lpSaTotal', total);
            setValue('discountInEuro', discount);
            setValue('totalPrice', total - (discount || 0));
          }
        } else {
          if (listPrice) setValue('listPrice', grossToNet(listPrice));
          if (specialEquipment) setValue('specialEquipment', grossToNet(specialEquipment));
          if (lpSaTotal) {
            const total = grossToNet(listPrice) + grossToNet(specialEquipment);
            const discount = grossToNet(discountInEuro);
            setValue('lpSaTotal', total);
            setValue('discountInEuro', discount);
            setValue('totalPrice', total - (discount || 0));
          }
        }
      }
      if (name === 'vehicleCondition' && value.vehicleCondition === 1) {
        setValue('registrationDate', undefined);
      }
      if (name === 'modelId' && value.modelId) {
        fetchPredefinedValues(value.modelId);
      }
      if (
        name === 'vehicleCondition' &&
        showDemonstrationCarMessage &&
        value.vehicleCondition === 2
      ) {
        setValue('vehicleCondition', 3);
        pushNotificationHandler.publish(
          t('deals.new.modelConfiguration.body.vehicleDetails.demonstrationCarMessage'),
          'warning'
        );
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch, showDemonstrationCarMessage]);

  const handleImportSuccess = (modelId: number) => {
    if (modelId) {
      fetchPredefinedValues(modelId);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (state.context.model) {
      const sellerId = getValues('sellerId');
      reset({
        ...state.context.model,
        sellerId: sellerId,
      });
      setPricesIncludingVAT(state.context.model?.pricesIncludingVAT);
    }
    // eslint-disable-next-line
  }, [state.context.model]);

  return (
    <>
      <div className="stepper-footer">
        <Button disabled={open} className="white" onClick={() => setOpen(true)}>
          {t('back')}
        </Button>
        <h2 className="current-step">{t('deals.new.stepObjectSubtitle')}</h2>
        <Button variant="contained" disabled={isSubmitting} type="submit" form="model-form">
          {t('continue')}
        </Button>
      </div>
      <Divider />
      <div className="content">
        <div className="form" style={{ width: '100%' }}>
          <SplitPanel
            open={open}
            setOpen={setOpen}
            left={
              <ModelSelection
                sellerId={watch('sellerId')}
                onSellerIdChanged={onSellerIdChanged}
                onModelSelected={onModelSelectionChanged}
                onImportSuccess={handleImportSuccess}
              />
            }
            right={
              <ModelConfiguration
                onSubmit={onSubmit}
                control={control}
                pricesIncludingVAT={pricesIncludingVAT}
                getValues={getValues}
                setValue={setValue}
                isDistributionChannelEditable={isDistributionChannelEditable}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
