import { Box, Divider } from '@mui/material';
import { FormEvent } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { ModelConfigurationBody } from './ModelConfigurationBody';
import { ModelConfigurationHeader } from './ModelConfigurationHeader';
import { ModelFormValues } from './ModelForm';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form/dist/types/form';

interface ModelConfigurationProps {
  onSubmit: (event: FormEvent) => void;
  control: Control<ModelFormValues>;
  pricesIncludingVAT: boolean;
  setValue: UseFormSetValue<ModelFormValues>;
  getValues: UseFormGetValues<ModelFormValues>;
  isDistributionChannelEditable: boolean;
}

export function ModelConfiguration({
  onSubmit,
  control,
  pricesIncludingVAT,
  setValue,
  getValues,
  isDistributionChannelEditable,
}: ModelConfigurationProps) {
  const modelId = useWatch({ control, name: 'modelId' });

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }} id="model-form" noValidate>
      <div style={{ width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <ModelConfigurationHeader modelId={modelId} />
            <Divider orientation="horizontal" variant="middle" />
          <ModelConfigurationBody
              control={control}
              pricesIncludingVAT={pricesIncludingVAT}
              setValue={setValue}
              getValues={getValues}
              isDistributionChannelEditable={isDistributionChannelEditable}
            />
          </Box>
        </Box>
      </div>
    </form>
  );
}
