import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { FormDatePicker } from '../../../../../component/form/FormDatePicker';
import React, { useContext } from 'react';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { useWatch } from 'react-hook-form';
import RestHttpClient from '../../../../../common/RestHttpClient';
import { pushNotificationHandler } from '../../../../../common/PushNotification';
import { VinStatusIcon } from '../../../new/model/VinStatusIcon';

interface FinancesObjectPropetyDetailsProps {
  control: any;
  onRelevantChange: (name: string, value: unknown) => void;
  loading: boolean;
  readonly?: boolean;
  dealId?: number;
  modelId?: number;
  dealTypeId?: number;
  vehicleCondition?: number;
}

export const FinancesObjectPropetyDetails = ({
  control,
  onRelevantChange,
  loading,
  readonly,
  dealId,
  modelId,
  dealTypeId,
  vehicleCondition,
}: FinancesObjectPropetyDetailsProps) => {
  const { t } = useTranslation();

  const vinDate = useWatch({ control, name: 'vinConfig.vinDate' });
  const vinIssue = useWatch({ control, name: 'vinConfig.vinIssue' });
  const vinStatus = useWatch({ control, name: 'vinConfig.vinStatus' });
  const vinState = useWatch({ control, name: 'vinConfig.vinState' });

  const handleRelevantBlurEvent = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  const handleRegistrationDateBlur = async (value: Date | undefined) => {
    const isValidRegistrationDate = await validateVehicleAge(value);
    if (!isValidRegistrationDate) {
      pushNotificationHandler.publish(
        t('deals.new.modelConfiguration.body.vehicleDetails.thisCarIsTooOldToDoCalculationWithIt'),
        'warning'
      );
    } else {
      onRelevantChange('objectConfig.registrationDate', value);
    }
  };

  const validateVehicleAge = (registrationDate: Date | undefined): Promise<boolean> => {
    if (registrationDate === undefined || registrationDate === null) {
      return Promise.resolve(true);
    }
    if (dealId !== undefined && dealId !== null) {
      return RestHttpClient.validateVehicleAgeByDealId(dealId!, registrationDate!).then((res) => {
        return res.data.isValid;
      });
    } else {
      if (modelId === undefined || vehicleCondition === undefined || dealTypeId === undefined) {
        return Promise.resolve(true);
      } else {
        return RestHttpClient.validateVehicleAge(
          modelId!,
          vehicleCondition!,
          dealTypeId!,
          registrationDate!
        ).then((res) => {
          return res.data.isValid;
        });
      }
    }
  };

  const regDate = useWatch({ control, name: 'objectConfig.registrationDate' });

  return (
    <Box sx={{ width: 1 / 2, display: 'flex', flexDirection: 'column', pr: 3, pl: 2 }}>
      <h3>{t('deals.new.financesConfiguration.calculationDrawer.objectConfig.propertyDetails')}</h3>
      <Box sx={{ m: 1 }}>
        {regDate !== undefined && (
          <FormDatePicker
            control={control}
            name="objectConfig.registrationDate"
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.objectConfig.registrationDate'
            )}
            shrink
            disableUnderline
            disabled={readonly}
            onChange={(event) => handleRegistrationDateBlur(event.target.value)}
          />
        )}
      </Box>
      <FormNumberInput
        control={control}
        disabled={loading || readonly}
        sx={{ m: 1 }}
        name="objectConfig.initialMileage"
        label={t('deals.new.financesConfiguration.calculationDrawer.objectConfig.initialMileage')}
        shrink
        onBlur={handleRelevantBlurEvent}
      />
      <FormTextField
        control={control}
        disabled={loading || readonly}
        sx={{ m: 1 }}
        name="objectConfig.commissionNumber"
        label={t('deals.new.financesConfiguration.calculationDrawer.objectConfig.commissionNumber')}
        shrink
      />
      <Stack flexDirection="row" alignItems="center" m={1} gap={1}>
        <VinStatusIcon
          vinState={vinState}
          vinDate={vinDate}
          vinIssue={vinIssue}
          vinStatus={vinStatus}
        />
        <FormTextField
          control={control}
          name="vinConfig.chassisNumber"
          label={t('deals.new.modelConfiguration.body.vehicleDetails.chassisNumber')}
          onBlur={handleRelevantBlurEvent}
        />
      </Stack>
    </Box>
  );
};
