import { assign, createMachine } from 'xstate';
import { ModelFormValues } from '../model/ModelForm';
import { FinancesFormValues } from '../finances/FinancesForm';
import { CustomerContextValues } from '../customer/CustomerForm';
import { Applicant } from '../applicant/ApplicantForm';
import RestHttpClient from '../../../../common/RestHttpClient';
import { history } from '../../../App';
import {
  mapCalculationToDto,
  mapCustomerContextToDto,
  mapModelContextToDto,
  mapSalesAidValuesToView,
} from './stateToDtoMapper';
import {
  CoOptIntermFile,
  FinancesCalculationContextValues,
  SalesAidValues,
} from '../../common/financeOverlay/RightDrawerSchema';
import {
  CustomerType,
  IImportModelConfigurationResult,
  SalesAidView,
  SaveDealRequest,
} from '../../../../generated/ApiClient';

const submitDeal = async ({
  model,
  customer,
  finances,
  applicants,
}: MultiStepFormMachineContext) => {
  if (model && customer && customer.customer && finances) {
    const deal = mapModelContextToDto(model);
    const dealCustomer = mapCustomerContextToDto(customer);
    const calculations = finances.calculations
      ? finances.calculations.map(mapCalculationToDto)
      : [];

    const salesAids: SalesAidView[] = finances.salesAids.map(mapSalesAidValuesToView);
    const coApplicants = applicants?.map((value) => mapCustomerContextToDto(value)) ?? [];

    const { data } = await RestHttpClient.saveDeal(
      new SaveDealRequest({
        deal,
        customer: dealCustomer,
        calculations,
        coApplicants,
        salesAids,
      })
    );

    const { dealId, calculations: savedCalcs } = data;
    if (finances.conversionOptionFiles && finances.conversionOptionFiles.length > 0) {
      await Promise.all(
        finances.conversionOptionFiles.map((intermFile) => {
          if (
            intermFile.financeId === undefined ||
            savedCalcs[intermFile.financeId] === undefined ||
            savedCalcs[intermFile.financeId].conversionOptionIds[intermFile.index] === undefined
          ) {
            return Promise.resolve();
          }
          return RestHttpClient.uploadSupplierInvoice(
            dealId,
            savedCalcs[intermFile.financeId].calculationId,
            savedCalcs[intermFile.financeId].conversionOptionIds[intermFile.index],
            {
              fileName: intermFile.file.name,
              data: intermFile.file,
            }
          );
        })
      );
    }

    const id = data.dealId;
    history.push('/deals/' + id);
  } else {
    return Promise.reject();
  }
};

export interface FinancesContextValues extends FinancesFormValues {
  calculations: FinancesCalculationContextValues[];
  salesAids: SalesAidValues[];
  conversionOptionFiles: CoOptIntermFile[];
}

export interface MultiStepFormMachineContext {
  loading?: boolean;
  model?: ModelFormValues;
  finances?: FinancesContextValues;
  customer?: CustomerContextValues;
  applicants?: Applicant[];
}

export const BACK = 'BACK';
export const CONFIRM_MODEL = 'CONFIRM_MODEL';
export const IMPORT_CONFIGURATION = 'IMPORT_CONFIGURATION';

export const CONFIRM_FINANCES = 'CONFIRM_FINANCES';
export const CONFIRM_OVERTAKEN_CUSTOMER = 'CONFIRM_OVERTAKEN_CUSTOMER';

export const SKIP_APPLICANT = 'SKIP_APPLICANT';
export const CONFIRM_CALCULATION = 'CONFIRM_CALCULATION';
export const ADD_SALES_AID = 'ADD_SALES_AID';
export const EDIT_SALES_AID = 'EDIT_SALES_AID';

export const CONFIRM_CUSTOMER = 'CONFIRM_CUSTOMER';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const CONFIRM_APPLICANT = 'CONFIRM_APPLICANT';
export const ADD_APPLICANT = 'ADD_APPLICANT';
export const REMOVE_APPLICANT = 'REMOVE_APPLICANT';

export type MultiStepFormMachineEvent =
  | {
      type: typeof BACK;
    }
  | {
      type: typeof CONFIRM_MODEL;
      info: ModelFormValues;
    }
  | {
      type: typeof IMPORT_CONFIGURATION;
      info: IImportModelConfigurationResult;
    }
  | {
      type: typeof CONFIRM_FINANCES;
      info: FinancesFormValues;
    }
  | {
      type: typeof CONFIRM_OVERTAKEN_CUSTOMER;
      info: CustomerContextValues;
    }
  | {
      type: typeof CONFIRM_CALCULATION;
      info: { finance: FinancesCalculationContextValues; intermFiles?: CoOptIntermFile[] };
    }
  | {
      type: typeof CONFIRM_CUSTOMER;
      info: CustomerContextValues;
    }
  | {
      type: typeof SKIP_APPLICANT;
      info: CustomerContextValues;
    }
  | {
      type: typeof RESET_CUSTOMER;
    }
  | {
      type: typeof ADD_SALES_AID;
      info: SalesAidValues;
    }
  | {
      type: typeof EDIT_SALES_AID;
      info: {
        salesAid: SalesAidValues;
        index: number;
      };
    }
  | {
      type: typeof CONFIRM_APPLICANT;
    }
  | {
      type: typeof ADD_APPLICANT;
      info: Applicant;
    }
  | {
      type: typeof REMOVE_APPLICANT;
      info: string;
    };

export const orderStateMachine = createMachine<
  MultiStepFormMachineContext,
  MultiStepFormMachineEvent
>(
  {
    id: 'newOrder',
    initial: 'model',
    states: {
      model: {
        initial: 'idle',
        id: 'model',
        onDone: {
          target: 'finances',
        },
        states: {
          idle: {
            on: {
              [CONFIRM_MODEL]: {
                target: 'complete',
                actions: ['assignModelInfoToContext'],
              },
              [IMPORT_CONFIGURATION]: {
                actions: ['assignImportDataToContext'],
              },
            },
          },
          complete: { type: 'final' },
        },
      },
      finances: {
        id: 'finances',
        onDone: {
          target: 'customer',
        },
        initial: 'idle',
        states: {
          idle: {
            on: {
              [CONFIRM_FINANCES]: {
                target: 'complete',
                actions: ['assignFinancesInfoToContext'],
              },
              [CONFIRM_CALCULATION]: {
                actions: ['assignCalculationInfoToContext'],
              },
              [CONFIRM_OVERTAKEN_CUSTOMER]: {
                actions: ['assignCustomerInfoToContext'],
              },
              [ADD_SALES_AID]: {
                actions: ['assignNewSalesAidToContext'],
              },
              [EDIT_SALES_AID]: {
                actions: ['assignEditedSalesAidToContext'],
              },
              [BACK]: {
                target: '#model',
              },
            },
          },
          complete: { type: 'final' },
        },
      },
      customer: {
        id: 'customer',
        onDone: {
          target: 'applicant',
        },
        initial: 'idle',
        states: {
          idle: {
            on: {
              [CONFIRM_CUSTOMER]: {
                target: 'complete',
                actions: ['assignCustomerInfoToContext'],
              },
              [SKIP_APPLICANT]: {
                target: 'submit',
                actions: ['assignCustomerAndLoadingInfoToContext'],
              },
              [RESET_CUSTOMER]: {
                actions: ['resetCustomerInfo'],
              },
              [BACK]: {
                target: '#finances',
              },
            },
          },
          submit: {
            invoke: {
              src: submitDeal,
              onDone: {
                target: 'complete',
                actions: assign({
                  loading: (_) => false,
                }),
              },
              onError: {
                target: 'idle',
                actions: assign({
                  loading: (_) => false,
                }),
              },
            },
          },
          complete: { type: 'final' },
        },
      },
      applicant: {
        id: 'applicant',
        onDone: {
          target: 'success',
        },
        initial: 'idle',
        states: {
          idle: {
            on: {
              [ADD_APPLICANT]: {
                actions: ['assignApplicantInfoToContext'],
              },
              [REMOVE_APPLICANT]: {
                actions: ['removeApplicantInfoFromContext'],
              },
              [CONFIRM_APPLICANT]: {
                target: 'submit',
                actions: ['assignLoadingToContext'],
              },
              [BACK]: {
                target: '#customer',
              },
            },
          },
          submit: {
            invoke: {
              src: submitDeal,
              onDone: {
                target: 'complete',
                actions: assign({
                  loading: (_) => false,
                }),
              },
              onError: {
                target: 'idle',
                actions: assign({
                  loading: (_) => false,
                }),
              },
            },
          },
          complete: { type: 'final' },
        },
      },
      success: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      assignModelInfoToContext: assign((context, event) => {
        if (event.type !== CONFIRM_MODEL) return {};

        const info = event.info;
        context.finances = {
          businessType: info.businessType,
          calculationBasisExclVAT: info.pricesIncludingVAT
            ? (info.totalPrice / 6) * 5
            : info.totalPrice,
          calculationBasisInclVAT: info.pricesIncludingVAT
            ? info.totalPrice
            : (info.totalPrice * 6) / 5,
          discountCategory: info.discountCategory,
          listPrice: info.listPrice,
          purchasePrice: info.totalPrice,
          pricesIncludingVAT: info.pricesIncludingVAT,
          calculations: [],
          salesAids: [],
          conversionOptionFiles: [],
        };

        return {
          ...context,
          model: info,
        };
      }),
      assignImportDataToContext: assign((context, event) => {
        if (event.type !== IMPORT_CONFIGURATION) return {};

        const { deal, customer } = event.info;

        const purchasePrice = deal.listPrice + deal.extrasPrice - deal.discountInEuro;
        return {
          ...context,
          model: {
            businessType: deal.dealTypeId || undefined,
            distributionChannel: deal.distributionChannel || undefined,
            discountCategory: deal.discountCategoryId || undefined,
            pricesIncludingVAT: deal.isIncludeVat,
            modelAddition: deal.modelAddition || undefined,
            vehicleCondition: deal.vehicleTypeConditionId,
            numberOfVehicles: deal.numberOfVehicles,
            registrationDate: deal.registrationDate,
            mileage: deal.initialMileage,
            commissionNumber: deal.commissionNumber || undefined,
            listPrice: deal.listPrice,
            specialEquipment: deal.extrasPrice,
            lpSaTotal: deal.listPrice + deal.extrasPrice,
            discountInPercentages: deal.discountPercent,
            discountInEuro: deal.discountInEuro,
            totalPrice: deal.purchasePrice || purchasePrice,
            modelId: deal.modelId,
            sellerId: deal.salesPersonId || undefined,
            modelPrototypeId: deal.modelPrototypeId || undefined,
          },
          customer: {
            customerId: customer.customerId ? customer.customerId?.toString() : '',
            type: deal.dealTypeId === 1 ? CustomerType.Private : CustomerType.Commercial,
            customer: customer.privateCustomer || customer.commercialCustomer,
          },
        };
      }),
      assignFinancesInfoToContext: assign((context, event) => {
        if (event.type !== CONFIRM_FINANCES) return {};
        return {
          ...context,
          finances: {
            ...context.finances,
            ...event.info,
          },
        };
      }),

      assignCalculationInfoToContext: assign((context, event) => {
        if (event.type !== CONFIRM_CALCULATION || !context.finances) return {};

        const { finance, intermFiles } = event.info;

        return {
          ...context,
          finances: {
            ...context.finances,
            conversionOptionFiles: [
              ...context.finances.conversionOptionFiles,
              ...(intermFiles || []),
            ],
            calculations: [
              ...context.finances.calculations
                .filter((c) => c.id !== finance.id)
                .map((c) => ({
                  ...c,
                  chassisNumber: finance.vinConfig.chassisNumber,
                  vinStatus: finance.vinConfig.vinStatus,
                  vinIssue: finance.vinConfig.vinIssue,
                  vinDate: finance.vinConfig.vinDate,
                  vinState: finance.vinConfig.vinState,
                })),
              finance,
            ],
          },
          model: {
            ...context.model,
            chassisNumber: finance.vinConfig.chassisNumber,
            vinStatus: finance.vinConfig.vinStatus,
            vinIssue: finance.vinConfig.vinIssue,
            vinDate: finance.vinConfig.vinDate,
            vinState: finance.vinConfig.vinState,
          },
        };
      }),

      assignNewSalesAidToContext: assign((context, event) => {
        if (event.type !== ADD_SALES_AID || !context.finances) return {};
        return {
          ...context,
          finances: {
            ...context.finances,
            salesAids: [...context.finances.salesAids, event.info],
          },
        };
      }),

      assignEditedSalesAidToContext: assign((context, event) => {
        if (event.type !== EDIT_SALES_AID || !context.finances) return {};
        const { index, salesAid } = event.info;
        const newSalesAids = [...context.finances.salesAids];
        newSalesAids[index] = salesAid;
        return {
          ...context,
          finances: {
            ...context.finances,
            salesAids: newSalesAids,
          },
        };
      }),

      assignCustomerInfoToContext: assign((context, event) => {
        if (event.type !== CONFIRM_CUSTOMER && event.type !== CONFIRM_OVERTAKEN_CUSTOMER) return {};
        return {
          ...context,
          customer: event.info,
        };
      }),
      assignCustomerAndLoadingInfoToContext: assign((context, event) => {
        if (event.type !== SKIP_APPLICANT) return {};
        return {
          ...context,
          loading: true,
          customer: event.info,
        };
      }),
      resetCustomerInfo: assign((context, event) => {
        if (event.type !== RESET_CUSTOMER) return {};
        return { ...context, customer: undefined };
      }),
      assignApplicantInfoToContext: assign((context, event) => {
        if (event.type !== ADD_APPLICANT) return {};

        const applicants = context.applicants ?? [];
        return {
          ...context,
          applicants: [
            ...applicants.filter((a) => a.customerId !== event.info.customerId),
            event.info,
          ],
        };
      }),
      removeApplicantInfoFromContext: assign((context, event) => {
        if (event.type !== REMOVE_APPLICANT) return {};

        const applicants: Applicant[] = context.applicants ?? [];
        return {
          ...context,
          applicants: applicants.filter((a) => a.customerId !== event.info),
        };
      }),
      assignLoadingToContext: assign((context, event) => {
        if (event.type !== CONFIRM_APPLICANT) return {};
        return {
          ...context,
          loading: true,
        };
      }),
    },
  }
);
